import observe from '@utils/observer';
import { delay, fireEvent } from '@utils/utilities';

const CLASS_AUTOCOMPLETE = 'autocomplete';
const CLASS_AUTOCOMPLETE_GROUP = 'autocomplete-group';

export class Autocomplete {

    /**
     * constructor of the Autocomplete
     * @return {*}
     */
    constructor() {

        let xhr_autocomplete = null;

        $('.autocomplete').prop('autocomplete', 'new-password');
        $('.autocomplete').closest('form').prop('autocomplete', 'off');

        $('.autocomplete-group').each( function( k, autocomplete_group ) {
            //find('.autocomplete').serialize()
            if( $(autocomplete_group).find('.autocomplete.autocomplete-primary').val() ) {
                $(autocomplete_group).find('.autocomplete').prop('readonly', true);
                $(autocomplete_group).find('.autocomplete-clear').show();
            } else {
                $(autocomplete_group).find('.autocomplete-update-btn, .autocomplete-change-btn').hide();
            }
        });

        $('body').off('click', '.autocomplete-change-btn').on('click', '.autocomplete-change-btn', function(e) {
            e.preventDefault();
            $(this).hide();
            var autocomplete_group = $(this).closest('.autocomplete-group');
            if( autocomplete_group.length ) {
                autocomplete_group.find('.autocomplete').prop('readonly', false).val('');
            } else {
                $('.autocomplete').prop('readonly', false).val('');
            }
        });

        $('body').off('click', '.autocomplete-update-btn').on('click', '.autocomplete-update-btn', function(e) {
            e.preventDefault();
            $(this).hide();
            var autocomplete_group = $(this).closest('.autocomplete-group');
            if( autocomplete_group.length ) {
                autocomplete_group.find('.autocomplete:not(.autocomplete-primary)').prop('readonly', false);
                autocomplete_group.find('.autocomplete-update').val('1');
            } else {
                $('.autocomplete:not(.autocomplete-primary)').prop('readonly', false);
                $('.autocomplete-update').val('1');
            }
        });

        $('body').off('input', 'input.autocomplete').on('input', 'input.autocomplete', function() {
            var $this = $(this);

            if( $this.val().length >= 2 ) {
                var autocomplete_group  = $this.closest('.autocomplete-group');
                var autocomplete_url	= autocomplete_group.data('href') ? autocomplete_group.data('href') : '/helpers/autocomplete';

                if( autocomplete_group.length ) {
                    var autocomplete_data = autocomplete_group.find('.autocomplete').serialize();

                } else {
                    var autocomplete_data = $('.autocomplete').serialize();
                }

                delay(
                    function() {
                        if( xhr_autocomplete ) xhr_autocomplete.abort();
                        xhr_autocomplete = $.ajax({
                            type: 'POST',
                            data: autocomplete_data,
                            url: autocomplete_url,
                            success: function(data) {
                                $('#autocomplete').remove();
                                ajax_actions.handle_response(data, $this.parent());
                            },
                            dataType: 'json',
                            async: true
                        });
                    }, 200
                );
            }
        }).on('blur', 'input.autocomplete', function(e) {
            delay(
                function() {
                    $('#autocomplete').remove();
                }, 500
            );
        });

        $('body').on('click', '#autocomplete a', function(e) {
            e.preventDefault();
            var autocomplete = $(this);
            $.each(autocomplete.data(), function(key, value) {
                if( value ) {
                    var element = autocomplete.closest('.autocomplete-group').find('[name="' + key + '"]')
                    element
                        .val(value)
                        .prop('readonly', true)
                        .addClass('c-autocomplete__frozen');

					if (element.hasClass('selectized'))
						element[0].selectize.setValue(value);

                    if(typeof element[0] !== 'undefined')
                        fireEvent('change', element[0], {type: 'change', node: element[0]});
                }
            });

            const target = autocomplete.closest('.autocomplete-group').find('.relative');
            $('<a href="#" class="c-autocomplete__clear"></a>').appendTo(target);
            autocomplete.closest('.autocomplete-group').find('.autocomplete-update-btn, .autocomplete-change-btn').show();
            $('#autocomplete').remove();
        })

        $('body').on('click', '.c-autocomplete__clear', function(e) {
            var autocomplete = $(this);
            autocomplete
                .closest('.autocomplete-group')
                .find('.c-autocomplete__frozen')
                .trigger('chosen:updated')
                .removeAttr('readonly')
                .removeClass('c-autocomplete__frozen')
                .val('');

            autocomplete.remove();
        });

    }

}

observe(`.${CLASS_AUTOCOMPLETE}`, () => {
    new Autocomplete();
});

observe(`.${CLASS_AUTOCOMPLETE_GROUP}`, () => {
    new Autocomplete();
});
