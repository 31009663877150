import 'modules/jquery-ui/ui/widgets/draggable';
import 'modules/jquery-ui/ui/widgets/droppable';
import 'modules/jquery-ui/ui/widgets/sortable';

import observe from '@utils/observer';

const CLASS_SORTABLE = 'sortable';

export class Sortable {
    /**
     * constructor of the sortable
     * @return {*}
     */
    constructor() {
        $('.sortable.ui-sortable').sortable('destroy');

        $('.sortable').sortable({
            items: $(this).data('item'),
            handle: $(this).data('handle') ? $(this).data('handle') : '.sortable-handle',
            animation: 150,
            update: function(event) {
                $.ajax({
                    type: 'POST',
                    url: $(event.target).data('sortable-callback'),
                    data: {
                        ordering: $(this).sortable('serialize')
                    },
                    success: (data) => {
                        ajax_actions.handle_response(data, $(event.target));
                    },
                    dataType: 'json',
                    async: true
                });
            },
            helper: function(e, ui) {
                ui.children().each(function() {
                    $(this).width($(this).width());
                });
                return ui;
            }
        });
    }
}

observe(`.${CLASS_SORTABLE}`, () => {
    new Sortable();
});
