import observe from '@utils/observer';
import {
    confirm,
    fireEvent,
    closest,
    serialize
} from '@utils/utilities';

const CLASS_AJAX_CHANGE = 'ajax-change';
const CLASS_AJAX_ACTION = 'ajax-action';
const CLASS_INLINE_AJAX_FORM = 'inline-ajax-form';
const CLASS_AJAX_FORM = 'ajax-form';
const CLASS_SUBMIT_AJAX_FORM = 'submit-ajax-form';
const CLASS_DELETE_ROW = 'table-delete-row';

const loadingIcon = '<span class="icn is-loading"><div class="icn__box icn__box--small"><svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg></div></span>';
const loadingIconClean = '<span class="icn"><svg class="spinner" width="24px" height="24px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg></span>';

export class AjaxObserver {
    static init() {
        new AjaxObserver();
    }

    /**
     * constructor of the Tooltip
     * @return {*}
     */
    constructor() {
        document.addEventListener('ajax:action', (e) => this.handleAction(e));

        observe(`.${CLASS_AJAX_CHANGE}`, (node) => this.addChangeListener(node));
        observe(`.${CLASS_AJAX_ACTION}`, (node) => this.addActionListener(node));
        observe(`.${CLASS_DELETE_ROW}`, (node) => this.deleteRowListener(node));
        observe(`.${CLASS_AJAX_FORM}`, (node) => this.initAjaxForm(node));
        observe(`.${CLASS_SUBMIT_AJAX_FORM}`, (node) => this.initSubmitAjaxForm(node));
        observe(`.${CLASS_INLINE_AJAX_FORM}`, (node) => this.initInlineAjaxForm(node));
    }

    initAjaxForm(node) {
        node.addEventListener('submit', (e) => this.handleAjaxForm(e, node));
    }

    handleAjaxForm(event, formNode) {
        event.preventDefault();

        const button = formNode.querySelector('.c-btn:focus') || formNode.querySelector('.button:focus');
        const endpoint = formNode.getAttribute('action');
        const data = serialize(formNode);

        this.submitAjaxForm(formNode, endpoint, data, $(button));
    }

    initSubmitAjaxForm(node) {
        node.addEventListener('click', (e) => this.handleSubmitAjaxForm(e, node));
    }

    handleSubmitAjaxForm(event, node) {
        event.preventDefault();

        const formNode = document.querySelector(node.getAttribute('data-selector'));
        const endpoint = formNode.getAttribute('action');
        let data = serialize(formNode);
        const extraData = node.dataset;

        Object.keys(extraData).forEach((item) => {
            data += `&${item}=${extraData[item]}`;
        });

        this.submitAjaxForm(formNode, endpoint, data, $(node));
    }

    initInlineAjaxForm(node) {
        const submitNode = node.querySelector('.submit-form');
        submitNode.addEventListener('click', (e) => this.handleInlineAjaxForm(e, submitNode));
    }

    handleInlineAjaxForm(event, submitNode) {
        event.preventDefault();

        const formNode = closest(submitNode, '.inline-ajax-form');
        const endpoint = formNode.getAttribute('data-action');
        const data = serialize(formNode);

        this.submitAjaxForm(formNode, endpoint, data);
    }

    submitAjaxForm(formNode, endpoint, data, button = false) {
        if (button && button.find('.icn').length === 0) {
            button.prepend(loadingIconClean);
        }

        if (formNode.classList.contains('confirm') && confirm(formNode) !== true) {
            return false;
        }

        window.onbeforeunload = () => 'Are you sure?';
        this.disableFrom(formNode);

        $.ajax({
            type: 'POST',
            url: endpoint,
            data,
            dataType: 'json',
            async: true,
            success: (response) => {
                window.onbeforeunload = null;
                ajax_actions.handle_response(response, $(formNode), button);
            }
        });

        return true;
    }

    disableFrom(formNode) {
        const errorNodes = Array.from(formNode.querySelectorAll('input.error'));
        const formElements = Array.from(formNode.querySelectorAll('input, button, select, textarea'));
        const formSubmit = Array.from(formNode.querySelectorAll('input[type="submit"], button'));

        errorNodes.forEach((node) => {
            node.classList.remove('error', 'show');
        });

        formElements.forEach((node) => {
            node.readOnly = true;
        });

        formSubmit.forEach((node) => {
            node.disabled = true;
        });
    }

    addActionListener(node) {
        node.addEventListener('click', (e) => this.fireEvent(e, node));
    }

    addChangeListener(node) {
        node.addEventListener('change', (e) => this.fireEvent(e, node));
    }

    fireEvent(event, node) {
        event.preventDefault();
        fireEvent('ajax:action', node, { type: 'change', node });
    }

    deleteRowListener(node) {
        node.addEventListener('click', (e) => this.deleteRow(e, node));
    }

    deleteRow(event, node) {
        const row = closest(node, 'tr');
        row.parentNode.removeChild(row);
    }

    handleAction(event) {
        document.body.style.cursor = 'progress';

        const { node } = event.detail;
        const data = typeof node.dataset !== 'undefined' ? node.dataset : [];
        const destination = node.hasAttribute('href') ? node.getAttribute('href') : data.href;

        if (node.classList.contains('confirm') && confirm(node) !== true) {
            document.body.style.cursor = '';
            return false;
        }

        if (node.type === 'hidden' || node.type === 'text' || node.nodeName === 'SELECT') {
            data[node.getAttribute('name').replace(/[\[\]]+/g, '')] = node.value;
            delete data.chosen;
        }

        if (node.type === 'checkbox') {
            data[node.getAttribute('name')] = node.checked;
            delete data.chosen;
        }

        $.ajax({
            type: 'POST',
            url: destination,
            data,
            success: (response) => {
                ajax_actions.handle_response(response, $(node));
            },
            dataType: 'json',
            async: true
        });

        return true;
    }
}

AjaxObserver.init();
