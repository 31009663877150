import './scss/main.scss';

import { SentryInit } from '@utils/raven';
import Core from '@utils/core';
import components from './component-list';

// Items on the list for cleaning up
import './js/utilities/ajax-actions';
import './js/briefing-creator';
import './js/add-list-step';
import './js/event-user';

// Components
import './components/ajax-observer/ajax-observer';
import './components/autocomplete/autocomplete';
import './components/modal/modal';
import './components/sortable/sortable';
import './components/spotlight/spotlight';
import './components/overlay/overlay';
import './components/sumo-select/sumo-select';
import './components/ping/ping';

(function index() {
    // Get sentry started as first
    SentryInit();

    const setupCore = () => {
        // The core scans the page for components and loads the JS dynamic.
        // After the scan we start the observer for dynamically added nodes.
        const core = new Core(components);
        core.scan();
        core.init();
    };

    // // const setupLang = () => {
    // //     const langProvider = new LangProvider();
    // //     langProvider.init();
    // // };

    const boot = () => {
        // setupLang();
        setupCore();
    };
    document.addEventListener('DOMContentLoaded', boot);
})();

const namespace = document.currentScript.src;
const assetPrefixForNamespace = () => {
    if (namespace.indexOf('/dist/') > 0)
        return namespace.substr(0, namespace.indexOf('/dist/') + 6);

    return '/dist/';
};

if (__DEV__ !== true) {
    // eslint-disable-next-line camelcase
    __webpack_public_path__ = `${assetPrefixForNamespace(namespace)}`;
}
