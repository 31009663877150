/* eslint-disable prettier/prettier */
const components = {
    'ag-grid':
        () => import(
            /* webpackChunkName: "ag-grid" */
            './components/ag-grid/index'
        ),
    'ag-pivot':
        () => import(
            /* webpackChunkName: "ag-grid" */
            './components/ag-pivot/index'
        ),
    'accordion':
        () => import(
            /* webpackChunkName: "accordion" */
            './components/accordion/accordion'
        ),
    'accreditations':
        () => import(
            /* webpackChunkName: "accreditations" */
            './components/accreditations/accreditations'
        ),
    'color-picker':
        () => import(
            /* webpackChunkName: "color-picker" */
            './components/color-picker/color-picker'
        ),
    'cropper':
        () => import(
            /* webpackChunkName: "cropper" */
            './components/cropper/cropper'
        ),
    'counter':
        () => import(
            /* webpackChunkName: "counter" */
            './components/counter/counter'
        ),
    'datatables':
        () => import(
            /* webpackChunkName: "datatables" */
            './components/datatables/datatables'
        ),
    'date-picker':
        () => import(
            /* webpackChunkName: "input-date-picker" */
            './components/input-date-picker/input-date-picker'
        ),
    'dashboard':
        () => import(
            /* webpackChunkName: "dashboard" */
            './compositions/dashboard/dashboard'
        ),
    'dropdown':
        () => import(
            /* webpackChunkName: "dropdown" */
            './components/dropdown/dropdown'
        ),
    'dynamic-request':
        () => import(
            /* webpackChunkName: "dynamic-request" */
            './components/dynamic-request/dynamic-request'
        ),
    'enable-button':
        () => import(
            /* webpackChunkName: "enable-button" */
            './components/enable-button/enable-button'
        ),
    'enable-section':
        () => import(
            /* webpackChunkName: "enable-section" */
            './components/enable-section/enable-section'
        ),
    'export-table':
        () => import(
            /* webpackChunkName: "export-table" */
            './components/export-table/export-table'
        ),
    'formatter':
        () => import(
            /* webpackChunkName: "formatter" */
            './components/formatter/formatter'
        ),
    'flex-table':
        () => import(
            /* webpackChunkName: "flex-table" */
            './components/flex-table/flex-table'
        ),
    'launchpad':
        () => import(
            /* webpackChunkName: "launchpad" */
            './components/launchpad/launchpad'
        ),
    'list-filter':
        () => import(
            /* webpackChunkName: "list-filter" */
            './components/list-filter/list-filter'
        ),
    'main-menu':
        () => import(
            /* webpackChunkName: "main-menu" */
            './components/main-menu/main-menu'
        ),
    'modules':
        () => import(
            /* webpackChunkName: "modules" */
            './components/modules/modules'
        ),
    'multiplier':
        () => import(
            /* webpackChunkName: "multiplier" */
            './components/multiplier/multiplier'
        ),
    'mobile-ticket':
        () => import(
            /* webpackChunkName: "mobile-ticket" */
            './components/mobile-ticket/mobile-ticket'
        ),
    'notification-bar':
        () => import(
            /* webpackChunkName: "notification-bar" */
            './components/notification-bar/notification-bar'
        ),
    'page-composer':
        () => import(
            /* webpackChunkName: "page-composer" */
            './compositions/page-composer/page-composer'
        ),
    'prio-menu':
        () => import(
            /* webpackChunkName: "prio-menu" */
            './components/prio-menu/prio-menu'
        ),
    'progress-header':
        () => import(
            /* webpackChunkName: "progress-header" */
            './components/progress-header/progress-header'
        ),
    'search-all':
        () => import(
            /* webpackChunkName: "search-all" */
            './components/search-all/search-all'
        ),
    'selectize':
        () => import(
            /* webpackChunkName: "selectize" */
            './components/selectize/selectize'
        ),
    'select-checkboxes':
        () => import(
            /* webpackChunkName: "select-checkboxes" */
            './components/checkbox/checkbox'
        ),
    'select-list':
        () => import(
            /* webpackChunkName: "select-list" */
            './components/select-list/select-list'
        ),
    'setup-guide':
        () => import(
            /* webpackChunkName: "setup-guide" */
            './components/setup-guide/setup-guide'
        ),
    'sub-menu-list':
        () => import(
            /* webpackChunkName: "sub-menu-list" */
            './components/list/sub-menu-list'
        ),
    'step-modal':
        () => import(
            /* webpackChunkName: "step-modal" */
            './components/step-modal/step-modal'
        ),
    'tabs':
        () => import(
            /* webpackChunkName: "tabs" */
            './components/tabs/tabs'
        ),
    'table':
        () => import(
            /* webpackChunkName: "table" */
            './components/table/table'
        ),
    'timetable':
        () => import(
            /* webpackChunkName: "timetable" */
            './components/timetable/timetable-new'
        ),
    'toggle-link':
        () => import(
            /* webpackChunkName: "toggle-link" */
            './components/toggle-link/toggle-link'
        ),
    'tooltip':
        () => import(
            /* webpackChunkName: "tooltip" */
            './components/tooltip/tooltip'
        ),
    'tooltip-input':
        () => import(
            /* webpackChunkName: "tooltip-input" */
            './components/tooltip-input/index'
        ),
    'total-price':
        () => import(
            /* webpackChunkName: "total-price" */
            './components/total-price/total-price'
        ),
    'remarks':
        () => import(
            /* webpackChunkName: "remarks" */
            './components/remarks/remarks'
        ),
    'rfid-scanner':
        () => import(
            /* webpackChunkName: "rfid-scanner" */
            './components/rfid-scanner/rfid-scanner'
        ),
    'user-sheet':
        () => import(
            /* webpackChunkName: "user-sheet" */
            './components/user-sheet/Main'
        ),
    'uploader':
        () => import(
            /* webpackChunkName: "uploader" */
            './components/uploader/uploader'
        ),
    'wysiwyg':
        () => import(
            /* webpackChunkName: "wysiwyg" */
            './components/wysiwyg/wysiwyg'
        )
};

export default components;
